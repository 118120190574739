
        import {css} from 'lit-element';   
        export default css`:host {
  display: block;
  width: 100%;
  height: 100%;
}

md-theme {
  display: block;
  height: 100%;
}

::slotted(*) {
  height: calc(100% - 24px);
  max-height: calc(100vh - 24px);
}
/*# sourceURL=/app/src/assets/styles/wrapper.scss */
/*# sourceURL=wrapper.scss */
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvc3JjL2Fzc2V0cy9zdHlsZXMvd3JhcHBlci5zY3NzIiwid3JhcHBlci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0FDQ0Y7O0FERUE7RUFDRSxjQUFBO0VBQ0EsWUFBQTtBQ0NGOztBREVBO0VBQ0UseUJBQUE7RUFDQSw4QkFBQTtBQ0NGIiwiZmlsZSI6IndyYXBwZXIuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG4gIHdpZHRoOiAxMDAlO1xuICBoZWlnaHQ6IDEwMCU7XG59XG5cbm1kLXRoZW1lIHtcbiAgZGlzcGxheTogYmxvY2s7XG4gIGhlaWdodDogMTAwJTtcbn1cblxuOjpzbG90dGVkKCopIHtcbiAgaGVpZ2h0OiBjYWxjKDEwMCUgLSAyNHB4KTtcbiAgbWF4LWhlaWdodDogY2FsYygxMDB2aCAtIDI0cHgpO1xufVxuIiwiOmhvc3Qge1xuICBkaXNwbGF5OiBibG9jaztcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogMTAwJTtcbn1cblxubWQtdGhlbWUge1xuICBkaXNwbGF5OiBibG9jaztcbiAgaGVpZ2h0OiAxMDAlO1xufVxuXG46OnNsb3R0ZWQoKikge1xuICBoZWlnaHQ6IGNhbGMoMTAwJSAtIDI0cHgpO1xuICBtYXgtaGVpZ2h0OiBjYWxjKDEwMHZoIC0gMjRweCk7XG59Il19 */`;
    